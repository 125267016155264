export function useHiddenConfiguration() {
  const configurationIds = ref({});

  const shouldBeHidden = (options) => {
    return (
      Object.values(options).every(({ type }) => "color" !== type) &&
      Object.keys(options).length > 3
    );
  };

  const buildHiddenConfigurations = (configurations) => {
    configurationIds.value = {};

    Object.values(configurations).forEach((configuration) => {
      setIsHiddenConfiguration(
        configuration.id,
        shouldBeHidden(configuration.options),
      );
    });
  };

  const setIsHiddenConfiguration = (configurationId, isHidden) => {
    configurationIds.value[configurationId] = isHidden;
  };

  const getIsHiddenConfiguration = (configurationId) => {
    return configurationIds.value[configurationId];
  };

  return {
    configurationIds,
    buildHiddenConfigurations,
    setIsHiddenConfiguration,
    getIsHiddenConfiguration,
    shouldBeHidden,
  };
}
