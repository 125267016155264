import { useAPI } from "~/uses/useMyFetch";

export const usePaymentPolicyStore = defineStore("paymentPolicyStore", {
  state: () => {
    return {
      policies: null,
      isFetching: false,
    };
  },
  actions: {
    async fetch() {
      if (null !== this.policies || this.isFetching) {
        return;
      }

      this.isFetching = true;
      await useAPI("/purchase/order/payment/policies")
        .then((data) => {
          this.policies = data;
        })
        .finally(() => {
          this.isFetching = false;
        });
    },
  },
  getters: {
    getIsPoliciesFetching: (state) => state.isFetching,
    getPrivatBankPayment: (state) => {
      return (paymentMethod, price) => {
        const orders = state.policies
          ?.find(({ type }) => type === "privatBankMerchant")
          .rules.find(({ method }) => method === paymentMethod).orders;

        const isAvailable =
          price >= orders[0].totalPrice.min.amount &&
          price <= orders[0].totalPrice.max.amount;

        return reactive({
          isAvailable,
          partsCount: orders[0].options.partsCount,
        });
      };
    },
  },
});
